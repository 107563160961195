import React from "react";
import img1 from "images/about1.png";
import img2 from "images/about2.png";
import img3 from "images/about3.png";
import img4 from "images/about4.png";
import NcImage from "shared/NcImage/NcImage";

const features = [
  {
    image: img1,
    title: "Wide Range of Vehicles",
    description:
      "From compact cars for quick errands to spacious SUVs for family trips, our diverse fleet ensures that you find the perfect vehicle for any occasion.",
  },
  {
    image: img2,
    title: "Competitive Rates",
    description:
      "Enjoy top-notch service without breaking the bank. Our transparent pricing and special offers make renting a car with us a smart and economical choice.",
  },
  {
    image: img3,
    title: "Exceptional Customer Service",
    description:
      "Our friendly and knowledgeable team is dedicated to providing a seamless rental experience. We're here to assist you every step of the way, ensuring your journey is smooth and enjoyable.",
  },
  {
    image: img4,
    title: "Convenient Location",
    description:
      "Located in the heart of Port St. Lucie, our rental office is easily accessible, making it convenient for you to pick up and drop off your rental vehicle.",
  },
];

const Features = () => {
  return (
    <div className="text-white py-12">
      <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h2 className="text-3xl font-extrabold mb-6">
          Why Choose Driven Enterprises?
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <div key={index} className="flex flex-col items-center">
              <NcImage
                src={feature.image}
                className="object-cover w-full h-full rounded-2xl"
              />
              <h3 className="text-lg font-bold my-2">{feature.title}</h3>
              <p className="text-center text-[#9CA3AF] text-sm">
                {feature.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Features;
