import rightImg2 from "images/about-hero-right2.png";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionHero4 from "components/SectionHero/SectionHero";
import HomeAboutSection from "containers/PageAbout/HomeAboutSection";
import GoPlacesWith from "components/GoPlacesWith/GoPlacesWith";
import ReadytoDrive from "containers/PageAbout/ReadytoDrive";
import readytodrive from "images/readytodrive.png";
import RentalPolicies from "components/RentalPolicies/RentalPolicies";

function PageHome3() {
  return (
    <div className="nc-PageHome3 relative overflow-hidden mt-4">
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />
      {/* <DownloadLink /> */}

      {/* SECTION HERO */}
      <div className="container relative lg:pb-16 lg:pt-8 md:pt-0 sm:pt-0 px-0 md:pb-0 sm:pb-0 ">
        <div className="container">
          <SectionHero4 className="" />
        </div>
      </div>
      <div className="container relative py-16">
        <SectionGridFeaturePlaces />
      </div>

      <div className="relative p-8 md:p-16 bg-black bg-opacity-20">
        <HomeAboutSection rightImg={rightImg2} />
      </div>
      <div className="relative p-8 md:p-16 bg-black bg-opacity-20">
        <GoPlacesWith />
      </div>
      <div className="container relative my-28">
        <BgGlassmorphism />

        <RentalPolicies />
      </div>
      <div className="relative mb-20 lg:mb-36">
        {/* <BackgroundSection /> */}
        <SectionClientSay uniqueClassName="PageAbout_" />
      </div>
      <div className="mt-12">
        <div className="p-8 md:p-16 ">
          {/* <BackgroundSection /> */}
          <ReadytoDrive
            rightImg={readytodrive}
            heading="Book your unique experience in Miami"
            btnText=""
            subHeading="If you are just arriving to Miami we can pick you up at Superautos at no additional cost. Live the experience from the moment you land and we will pick you up back wherever YOU want."
          />
        </div>
      </div>
    </div>
  );
}

export default PageHome3;
