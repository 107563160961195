import React, { FC, ReactNode } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface SectionHeroProps {
  className?: string;
  rightImg: string;
}

const HomeAboutSection: FC<SectionHeroProps> = ({
  className = "",
  rightImg,
}) => {
  return (
    <div
      className={`nc-SectionHero relative ${className} mb-20`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row items-center relative text-center lg:text-left lg:space-x-10">
        {/* Text Section with 50% width */}
        <div className="w-full lg:w-1/2 space-y-5 lg:space-y-7">
          <h2 className="text-3xl !leading-tight font-bold md:text-5xl text-[white]">
            Bring Your Style with Our Exotic Super Cars
          </h2>
          <span className="block text-base xl:text-lg text-white text-justify md:max-w-[90%]">
            Take the best photos and videos for your social networks, capture
            unique memories forever with our Supercars, they offer a perfect
            combination of power, luxury and sportiness. Contact us now and
            start planning your unforgettable driving experience in Miami!
          </span>
        </div>

        {/* Image Section with 50% width */}
        <div className="w-full mt-12 lg:mt-0 lg:w-1/2 flex-grow">
          <img className="w-full rounded-xl" src={rightImg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default HomeAboutSection;
